<script setup lang="ts">
import type { HomepageData } from "~/types/pages";
import { getSeoObject } from "~/helpers/seo";

onMounted(() => {
  // check if is client
  if (process.client) {
    let previousActiveTabIndex = 0;
    const tabSwitchers = document.querySelectorAll(
      ".wrapperfavorite .tab-switcher",
    ) as NodeListOf<HTMLElement>;

    tabSwitchers.forEach((tabSwitcher) => {
      tabSwitcher.addEventListener("click", handleTabSwitch);
      tabSwitcher.addEventListener("keypress", (event) => {
        if (event.key === "Enter") {
          handleTabSwitch(event);
        }
      });
    });

    /* eslint-disable */
    function handleTabSwitch(event: Event): void {
      const target = event.target as HTMLElement;
      const tabClicked = parseInt(target.dataset.tabIndex!, 10);

      if (tabClicked !== previousActiveTabIndex) {
        const allTabsContainer = document.querySelectorAll(
          "#allTabsContainer .tab-container",
        ) as NodeListOf<HTMLElement>;
        allTabsContainer.forEach((tab) => {
          if (parseInt(tab.dataset.tabIndex!, 10) === tabClicked) {
            document.querySelectorAll(".tab-container").forEach((container) => {
              (container as HTMLElement).style.display = "none";
            });
            tab.style.display = "block";
            previousActiveTabIndex = tabClicked;
          }
        });
      }
    }

    const toplinksItems = document.querySelectorAll(
      ".wrapperfavorite .toplinks li",
    ) as NodeListOf<HTMLLIElement>;
    toplinksItems.forEach((item) => {
      item.addEventListener("click", () => {
        const activeElement = item
          .closest(".toplinks")!
          .querySelector(".active") as HTMLElement;
        activeElement?.classList.remove("active");
        item?.classList.add("active");
      });
    });

    // Show the first tab and hide the rest
    const firstTradesmartItem = document.querySelector(
      "#tradesmart li:first-child",
    ) as HTMLLIElement;
    firstTradesmartItem?.classList.add("active");
    document.querySelectorAll(".tab-content").forEach((content, index) => {
      (content as HTMLElement).style.display = index === 0 ? "block" : "none";
    });

    // Click function
    const tradesmartItems = document.querySelectorAll(
      "#tradesmart li",
    ) as NodeListOf<HTMLLIElement>;
    tradesmartItems.forEach((item) => {
      item.addEventListener("click", (event) => {
        event.preventDefault();
        document.querySelectorAll("#tradesmart li").forEach((li) => {
          li?.classList.remove("active");
        });
        item?.classList.add("active");

        document.querySelectorAll(".tab-content").forEach((content) => {
          (content as HTMLElement).style.display = "none";
        });

        const activeTab = item.querySelector("a")!.getAttribute("href");
        const activeTabElement = document.querySelector(
          activeTab!,
        ) as HTMLElement;
        activeTabElement.style.display = "block";
      });
    });
  }

  useHead({ link: [{ rel: 'canonical', href: window.location.origin + "/" }] });
});

const { data } = useCustomFetch<HomepageData>("/api/strapi/homepage");

// useSeoMeta(getSeoObject(data.value?.seo as any));

const activeTabIndex = ref(0);
</script>

<template>
  <section v-if="data" id="slider">
    <SeoMeta v-bind="data?.seo" />
    <div class="my-animated-box animate-this">
      <div class="my-animated-box__children animate-this"></div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div id="mainsliderouter">
            <div id="mainslider" class="carousel slide index-motha-slider">
              <div class="carousel-indicators">
                <button v-for="(slide, index) in data?.slider" :key="slide.id" data-bs-target="#mainslider"
                  :data-bs-slide-to="index" :aria-label="`Slide ${index + 1}`" :class="{ active: index === 0 }"
                  :aria-current="index === 0"></button>
              </div>
              <div class="carousel-inner index-first-carousel">
                <div v-for="(slide, index) in data?.slider" :key="slide.id" class="carousel-item" :class="{
                  active: index === 0,
                  invert: slide.dark,
                }">
                  <div class="row">
                    <div class="col-md-5" data-aos="fade-down" data-aos-delay="100">
                      <h5>
                        {{ slide.title }}<br />
                        <small>{{ slide.smallTitle }}</small>
                      </h5>
                      <p style="white-space: pre-line">
                        {{ slide.subTitle }}
                      </p>
                      <div v-if="slide.advantages" class="elicon">
                        <span v-for="advantage in slide.advantages" :key="advantage.id">
                          <img :src="$getStrapiImageSrc(advantage.image)" alt="" height="20" />
                          {{ advantage.name }}
                        </span>
                      </div>
                      <div class="index-main-slider-buttonz">
                        <NuxtLinkLocale href="/register" class="buttons inline" data-aos="zoom-out"
                          data-aos-delay="300">
                          {{ slide.startButtonText }}
                        </NuxtLinkLocale>
                        <NuxtLinkLocale href="/register" class="buttons outline" data-aos="zoom-out"
                          data-aos-delay="600">
                          {{ slide.demoButtonText }}
                        </NuxtLinkLocale>
                      </div>
                    </div>
                    <div class="col-md-7">
                      <img :src="$getStrapiImageSrc(slide.image)" data-aos="zoom-out" class="d-block w-100" alt="..." />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section v-if="data" id="blocks" class="index-blocks">
    <div class="container index-container">
      <div class="row index-row">
        <div v-for="card in data?.infoCards" :key="card.id" class="col-md-3">
          <div class="glassblock">
            <img height="40" :src="$getStrapiImageSrc(card.icon)" alt="" class="icon" />
            <h2>{{ card.title }}</h2>
            <p>{{ card.text }}</p>
            <NuxtLinkLocale href="/about" class="buttons">{{
              card.buttonText
            }}</NuxtLinkLocale>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="whyus" class="index-why-us" v-if="data && data?.whyUs">
    <div class="container">
      <div class="effects">
        <div class="blureffect left" data-aos="zoom-out-left" data-aos-delay="400"></div>

        <div class="row">
          <div class="col-md-12">
            <div class="candle" data-aos="zoom-in-up" data-aos-delay="200"></div>
            <div class="headlines">
              <h2 class="h2-why-us">{{ data.whyUs.title }}</h2>
              <p class="why-us-sub-index">{{ data.whyUs.description }}</p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="glasswhy">
              <div class="row">
                <div class="col-xs-12 col-lg-6">
                  <h1 v-html="$mdRenderer.renderInline(data.whyUs.innerTitle)"></h1>
                  <p v-html="$mdRenderer.renderInline(data.whyUs.innerDescription)
                    "></p>
                </div>

                <div class="col-xs-12 col-lg-6">
                  <div class="row modileft">
                    <template v-for="(card, index) in data.whyUs.cards" :key="card.id">
                      <div v-if="index < 2" class="col-xs-12 col-lg-6">
                        <div class="glassblock">
                          <img height="64" :src="$getStrapiImageSrc(card.image)" alt="" class="icon" />
                          <h2>{{ card.title }}</h2>
                          <p style="white-space: pre-line">{{ card.text }}</p>
                        </div>
                      </div>
                    </template>
                  </div>

                  <div class="row nomargin">
                    <template v-for="(card, index) in data.whyUs.cards" :key="card.id">
                      <div v-if="index > 1" class="col-xs-12 col-lg-6">
                        <div class="glassblock">
                          <img height="64" :src="$getStrapiImageSrc(card.image)" alt="" class="icon" />
                          <h2>{{ card.title }}</h2>
                          <p style="white-space: pre-line">{{ card.text }}</p>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="blureffect right" data-aos="zoom-out-right" data-aos-delay="700"></div>
      </div>

      <div class="cardspayment" v-if="data?.paymentMethods">
        <p>{{ data.paymentMethods.text }}</p>
        <img v-for="method in data.paymentMethods.items.data" :key="method.id" class="desktopshow" style="height: 30px"
          :src="$getStrapiImageSrc(method, true)" alt="" />
      </div>

      <div v-if="data?.paymentMethods" id="index-carousel-payment-methods" class="carousel slide"
        data-bs-ride="carousel">
        <div class="carousel-inner carousel-inner-payment-methods">
          <div class="carousel-item active">
            <div>
              <template v-for="(method, index) in data.paymentMethods.items.data" :key="method.id">
                <img v-if="index < 4" style="height: 30px" :src="$getStrapiImageSrc(method, true)" />
              </template>
            </div>
          </div>
          <div class="carousel-item">
            <div>
              <template v-for="(method, index) in data.paymentMethods.items.data" :key="method.id">
                <img v-if="index > 3" style="height: 30px" :src="$getStrapiImageSrc(method, true)" />
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="tradesmart" class="index-tradesmart" v-if="data && data?.platforms">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="wrappertrade index-wrappertrade">
            <div class="blureffect right" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500"></div>

            <div class="tabs index-tabs">
              <ul id="tabs-nav" class="toplinks">
                <li v-for="(platform, index) in data.platforms" :key="platform.id"
                  :class="{ active: index === activeTabIndex }" @click.prevent="activeTabIndex = index">
                  <a :href="`#tab${index + 1}`">{{ platform.name }}</a>
                </li>
              </ul>
              <div id="tabs-content" class="index-tabs-content">
                <div v-for="(platform, index) in data.platforms" :key="platform.id" :id="`tab${index + 1}`"
                  class="tab-content" :style="{
                    display: index === activeTabIndex ? 'block' : 'none',
                  }">
                  <h2 style="white-space: pre-line">{{ platform.title }}</h2>
                  <div v-html="$mdRenderer.render(platform.description)"></div>

                  <NuxtLinkLocale href="https://web.24markets.com/en/sign/in" class="buttons">
                    {{ platform.buttonText }}
                  </NuxtLinkLocale>

                  <img :src="$getStrapiImageSrc(platform.image)" alt="" data-aos="fade-zoom-in" data-aos-delay="300"
                    class="mobiletrade index-mbltrd-one" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="favoritemarkets" class="index-favoritemarkets" v-if="data && data?.markets">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="headline index-headline">
            <h1 v-html="$mdRenderer.renderInline(data.markets.title)"></h1>

            <div class="candle" data-aos="zoom-in-up" data-aos-delay="200"></div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="wrapperfavorite index-wrapperfavorite">
            <ul class="toplinks">
              <li v-for="(market, index) in data.markets.items" class="tab-switcher" :class="{ active: index === 0 }"
                :rel="`tab${index + 1}`" :data-tab-index="index" tabindex="0">
                {{ market.name }}
              </li>
            </ul>
            <div id="allTabsContainer">
              <div v-for="(market, index) in data.markets.items" class="tab-container" :data-tab-index="index"
                :style="index !== 0 ? 'display: none' : ''">
                <div class="contentblock">
                  <div class="row">
                    <div class="col-xs-12 col-lg-6">
                      <h2>{{ market.title }}</h2>
                      <div v-html="$mdRenderer.render(market.description)"></div>

                      <NuxtLinkLocale href="/register" class="buttons">
                        {{ market.buttonText }}
                      </NuxtLinkLocale>
                      <NuxtLinkLocale href="/stocks" class="links">
                        {{ market.secondaryButtonText }}
                      </NuxtLinkLocale>
                    </div>

                    <div class="col-xs-12 col-lg-6">
                      <img :src="$getStrapiImageSrc(market.image)" alt="" data-aos="fade-up"
                        data-aos-anchor-placement="top-bottom" data-aos-delay="600" class="trstock" />
                    </div>
                  </div>

                  <div class="row index-stocks">
                    <div class="col-md-3">
                      <div class="itemstock">
                        <div class="yellow colorize" data-aos="fade-up" data-aos-anchor-placement="top-bottom"
                          data-aos-delay="300"></div>
                        <div class="glassblock">
                          <img src="/img/stock_1.webp" alt="" height="50" />
                          <h2>AMZN<span>Amazon</span></h2>
                          <NuxtLinkLocale href="/register">Trade</NuxtLinkLocale>
                          <hr />
                          <span class="price">$154</span>
                          <span class="gainlose">+0.43%</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="itemstock">
                        <div class="red colorize" data-aos="fade-up" data-aos-anchor-placement="top-bottom"
                          data-aos-delay="600"></div>
                        <div class="glassblock">
                          <img src="/img/stock_2.webp" alt="" height="40" />
                          <h2>TSLA<span>Tesla</span></h2>
                          <NuxtLinkLocale href="/register">Trade</NuxtLinkLocale>
                          <hr />
                          <span class="price">$232.7</span>
                          <span class="gainlose">-0.37%</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="itemstock">
                        <div class="green colorize" data-aos="fade-up" data-aos-anchor-placement="top-bottom"
                          data-aos-delay="900"></div>
                        <div class="glassblock">
                          <img src="/img/stock_3.webp" alt="" height="40" />
                          <h2>SPOT<span>Spotify</span></h2>
                          <NuxtLinkLocale href="/register">Trade</NuxtLinkLocale>
                          <hr />
                          <span class="price">$232.7</span>
                          <span class="gainlose">-0.37%</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="itemstock">
                        <div class="blue colorize" data-aos="fade-up" data-aos-anchor-placement="top-bottom"
                          data-aos-delay="1100"></div>
                        <div class="glassblock">
                          <img src="/img/stock_4.webp" alt="" height="40" />
                          <h2>META<span>Meta</span></h2>
                          <NuxtLinkLocale href="/register">Trade</NuxtLinkLocale>
                          <hr />
                          <span class="price">$232.7</span>
                          <span class="gainlose">-0.37%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="index-carousel" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                      <div class="carousel-item active">
                        <div class="col-md-3">
                          <div class="itemstock">
                            <div data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-delay="300"></div>
                            <div class="glassblock">
                              <img src="/img/stock_1.webp" alt="" height="50" />
                              <h2>AMZN<span>Amazon</span></h2>
                              <NuxtLinkLocale href="/register">Trade</NuxtLinkLocale>
                              <hr />
                              <span class="price">$154</span>
                              <span class="gainlose">+0.43%</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="carousel-item">
                        <div class="col-md-3">
                          <div class="itemstock">
                            <div data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-delay="600"></div>
                            <div class="glassblock">
                              <img src="/img/stock_2.webp" alt="" height="40" />
                              <h2>TSLA<span>Tesla</span></h2>
                              <NuxtLinkLocale href="/register">Trade</NuxtLinkLocale>
                              <hr />
                              <span class="price">$232.7</span>
                              <span class="gainlose">-0.37%</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="carousel-item">
                        <div class="col-md-3">
                          <div class="itemstock">
                            <div data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-delay="900"></div>
                            <div class="glassblock">
                              <img src="/img/stock_3.webp" alt="" height="40" />
                              <h2>SPOT<span>Spotify</span></h2>
                              <NuxtLinkLocale href="/register">Trade</NuxtLinkLocale>
                              <hr />
                              <span class="price">$232.7</span>
                              <span class="gainlose">-0.37%</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="carousel-item">
                        <div class="col-md-3">
                          <div class="itemstock">
                            <div data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-delay="1100"></div>
                            <div class="glassblock">
                              <img src="/img/stock_4.webp" alt="" height="40" />
                              <h2>META<span>Meta</span></h2>
                              <NuxtLinkLocale href="/register">Trade</NuxtLinkLocale>
                              <hr />
                              <span class="price">$232.7</span>
                              <span class="gainlose">-0.37%</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="bigplayer" class="index-bigplayer" v-if="data && data?.tradeLikeAPro">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1 v-html="$mdRenderer.renderInline(data.tradeLikeAPro.title)"></h1>
        </div>
      </div>
      <div class="effect">
        <div class="blureffect right" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500"></div>
        <div class="wraperbig">
          <div class="row">
            <div class="col-xs-12 col-lg-6">
              <h2 v-html="$mdRenderer.renderInline(data.tradeLikeAPro.innerTitle)"></h2>

              <p>
                <strong>{{ data.tradeLikeAPro.subTitle }}</strong>
              </p>

              <p>{{ data.tradeLikeAPro.description }}</p>

              <NuxtLinkLocale href="/register" class="buttons">
                {{ data.tradeLikeAPro.buttonText }}
              </NuxtLinkLocale>
              <NuxtLinkLocale href="/login" class="links">
                {{ data.tradeLikeAPro.secondaryButtonText }}
              </NuxtLinkLocale>
            </div>
            <div class="col-xs-12 col-lg-6">
              <img :src="$getStrapiImageSrc(data.tradeLikeAPro.image)" alt="" data-aos="fade-zoom-in"
                data-aos-delay="300" class="copytradingimg" />
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 text-center">
              <h2>{{ data.tradeLikeAPro.startTitle }}</h2>
            </div>
          </div>

          <div class="row index-three-steps">
            <div v-for="(step, index) in data.tradeLikeAPro.startSteps" :key="step.id" class="col-md-4">
              <div class="glassblock" data-aos="fade-up" data-aos-anchor-placement="top-bottom"
                :data-aos-delay="300 + step.id * 300" :class="{
                  noarrow: index + 1 === data.tradeLikeAPro.startSteps.length,
                }">
                <img :src="$getStrapiImageSrc(step.icon)" alt="" height="64"
                  class="stepico index-three-steps-stepico" />
                <h3>{{ step.title }}</h3>
                <p class="desci" style="white-space: pre-line">
                  {{ step.text }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="blureffect left" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500"></div>
      </div>
    </div>
  </section>

  <section v-if="data && data?.thePrimeSelection" id="primepromo">
    <div class="blureffect" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500"></div>

    <div class="blureffect right" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500"></div>

    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1 v-html="$mdRenderer.renderInline(data.thePrimeSelection.title)"></h1>
        </div>
      </div>

      <div class="glassblock">
        <div class="row">
          <div class="col-md-3">
            <div v-for="(card, index) in data.thePrimeSelection.leftItems" :key="card.id" class="glassblocksmall"
              data-aos="fade-up" data-aos-anchor-placement="top-bottom" :data-aos-delay="300 + index * 300"
              :class="{ left: index % 2 !== 0 }">
              <img :src="$getStrapiImageSrc(card.icon)" alt="" height="50" class="stepico" />
              <h3>{{ card.title }}</h3>
              <p class="desci" style="white-space: pre-line">
                {{ card.text }}
              </p>
            </div>
          </div>

          <div class="col-xs-12 col-lg-6">
            <img :src="$getStrapiImageSrc(data.thePrimeSelection.image)" alt="" data-aos="fade-zoom-in"
              data-aos-delay="300" class="iphonemac" />
            <div class="reviewall">
              <h1 v-html="$mdRenderer.renderInline(data.thePrimeSelection.bottomTitle)
                "></h1>
            </div>

            <div class="trustpilot">
              <span class="headline">Excellent</span><span class="star"></span><span class="star"></span><span
                class="star"></span><span class="star"></span><span class="halfstar"></span><span class="texts">4.6 out
                of
                5 based on 250 reviews
                <img src="/img/trustpilotmailnlogo.webp" alt="" class="trustlogo" /></span>
            </div>
          </div>

          <div class="col-md-3">
            <div v-for="(card, index) in data.thePrimeSelection.rightItems" :key="card.id" class="glassblocksmall"
              data-aos="fade-up" data-aos-anchor-placement="top-bottom" :data-aos-delay="1100 + index * 300"
              :class="{ right: index % 2 !== 0 }">
              <img :src="$getStrapiImageSrc(card.icon)" alt="" height="50" class="stepico" />
              <h3>{{ card.title }}</h3>
              <p class="desci" style="white-space: pre-line">
                {{ card.text }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="vippromo" class="index-vippromo" v-if="data && data?.vipClub">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="promoblock index-promoblock">
            <h1>{{ data.vipClub.title }}</h1>
            <div>
              <template v-for="advantage in data.vipClub.advantages" :key="advantage.id">
                <img :src="$getStrapiImageSrc(advantage.image)" alt="" class="icon" height="20" />
                <span>{{ advantage.name }}</span>
              </template>
            </div>

            <NuxtLinkLocale href="/register" class="buttons">
              <span>{{ data.vipClub.buttonText }}</span>
            </NuxtLinkLocale>
            <NuxtLinkLocale href="#" class="link">
              {{ data.vipClub.secondaryButtonText }}
            </NuxtLinkLocale>

            <img src="/img/bonuspack.webp" alt="" data-aos="zoom-out-down" class="bonuspack" />
            <img src="/img/candlebonus.webp" alt="" data-aos="zoom-in-up" data-aos-delay="200" class="candlebonus" />
          </div>
        </div>
      </div>
    </div>
  </section>

  <JoinCta />
</template>
